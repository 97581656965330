import { Image, Link } from '@snippets';
import { useRouter } from 'next/router';

export function MenuDrawer({
  handleMenuDrawerClose,
  handleMenuDrawerStayOpen,
  handleMenuHoverOut,
  menuDrawerContent,
  transparentBGEnabled,
}) {
  const { imageLinks, submenus, mainLink, useNewSubmenu, links } = {
    ...menuDrawerContent,
  };
  const hasContent =
    imageLinks?.length > 0 || submenus?.length > 0 || links?.length > 0;
  const { pathname } = useRouter();

  return (
    <div
      data-comp={MenuDrawer.displayName}
      className={`absolute left-0 top-[100%] hidden w-full origin-top border-border bg-background transition duration-200 lg:block ${
        hasContent ? 'scale-y-100 border-b' : 'scale-y-0'
      }`}
      onMouseEnter={handleMenuDrawerStayOpen}
      onMouseLeave={handleMenuHoverOut}
    >
      {hasContent && (
        <div
          className={`${
            useNewSubmenu
              ? 'flex flex-row justify-between'
              : 'grid grid-cols-[24rem_1fr] gap-5'
          } px-[152px] py-8 md:py-12 xl:px-[168px] ${
            transparentBGEnabled
              ? 'px-[177px] xl:px-[193px]'
              : pathname === '/'
              ? 'px-[177px] xl:px-[193px]'
              : ''
          }`}
        >
          {useNewSubmenu ? (
            <>
              {/* Submenus Section */}
              <div className="flex flex-1 flex-wrap gap-10">
                {submenus?.map((submenu, index) => {
                  const { submenuHeading, submenuItems } = submenu;

                  if (!submenuHeading || !submenuItems) return null;

                  return (
                    <div key={index} className="w-1/4 min-w-[200px]">
                      {/* Submenu Heading */}
                      <Link
                        aria-label={submenuHeading.text}
                        href={submenuHeading.link.url}
                        newTab={submenuHeading.link?.newTab}
                        className={`font-semibold ${
                          submenuHeading.fontSize === 'lg'
                            ? 'text-lg'
                            : 'text-base'
                        }`}
                        style={{ color: submenuHeading.color || 'inherit' }}
                        onClick={handleMenuDrawerClose}
                      >
                        {submenuHeading.text}
                      </Link>

                      {/* Submenu Items */}
                      <ul className="mt-2 flex flex-col gap-2">
                        {submenuItems.map(({ submenuItem }, itemIndex) => (
                          <li key={itemIndex}>
                            <Link
                              aria-hidden={!hasContent}
                              aria-label={submenuItem.text}
                              className="hover:text-underline"
                              href={submenuItem.url}
                              newTab={submenuItem?.newTab}
                              onClick={handleMenuDrawerClose}
                              tabIndex={hasContent ? '0' : '-1'}
                              type={submenuItem.type}
                            >
                              {submenuItem.text}
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </div>
                  );
                })}
              </div>

              {/* Main Link and Image Links Section */}
              <div className="flex flex-col items-start">
                {/* Main Link Section */}
                {mainLink?.text && (
                  <div className="mt-8">
                    <Link
                      aria-hidden={!hasContent}
                      aria-label={mainLink.text}
                      className="btn-primary"
                      href={mainLink.url}
                      newTab={mainLink.newTab}
                      onClick={handleMenuDrawerClose}
                      tabIndex={hasContent ? '0' : '-1'}
                      type={mainLink.type}
                    >
                      {mainLink.text}
                    </Link>
                  </div>
                )}

                {/* Image Links Section */}
                {imageLinks?.length > 0 && (
                  <ul className="mt-4 flex gap-2">
                    {imageLinks.map(({ alt, caption, image, link }, index) => (
                      <li key={index}>
                        <Link
                          aria-hidden={!hasContent}
                          aria-label={caption}
                          href={link?.url}
                          newTab={link?.newTab}
                          onClick={handleMenuDrawerClose}
                          tabIndex={hasContent ? '0' : '-1'}
                          type={link?.type}
                        >
                          <div className="relative aspect-[16/9] w-full overflow-hidden bg-offWhite">
                            {image?.src && (
                              <Image
                                alt={alt}
                                className="absolute left-1/2 top-1/2 h-full w-full -translate-x-1/2 -translate-y-1/2 object-cover"
                                crop="center"
                                height={Math.floor(400 / (16 / 9))}
                                src={image.src}
                                width="400"
                              />
                            )}
                          </div>

                          <p className="mt-3 text-sm">{caption}</p>
                        </Link>
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            </>
          ) : (
            <>
              <div>
                <ul className="flex flex-col gap-2">
                  {links?.map(({ link }, index) => {
                    return (
                      <li key={index}>
                        <Link
                          aria-hidden={!hasContent}
                          aria-label={link?.text}
                          className="hover-text-underline"
                          href={link?.url}
                          newTab={link?.newTab}
                          onClick={handleMenuDrawerClose}
                          tabIndex={hasContent ? '0' : '-1'}
                          type={link?.type}
                        >
                          {link?.text}
                        </Link>
                      </li>
                    );
                  })}
                </ul>

                {mainLink?.text && (
                  <Link
                    aria-hidden={!hasContent}
                    aria-label={mainLink.text}
                    className="btn-primary mt-5"
                    href={mainLink.url}
                    newTab={mainLink.newTab}
                    onClick={handleMenuDrawerClose}
                    tabIndex={hasContent ? '0' : '-1'}
                    type={mainLink.type}
                  >
                    {mainLink.text}
                  </Link>
                )}
              </div>

              <ul className="grid grid-cols-2 gap-5">
                {imageLinks?.map(({ alt, caption, image, link }, index) => {
                  return (
                    <li key={index}>
                      <Link
                        aria-hidden={!hasContent}
                        aria-label={caption}
                        href={link?.url}
                        newTab={link?.newTab}
                        onClick={handleMenuDrawerClose}
                        tabIndex={hasContent ? '0' : '-1'}
                        type={link?.type}
                      >
                        <div className="relative aspect-[16/9] w-full overflow-hidden bg-offWhite">
                          {image?.src && (
                            <Image
                              alt={alt}
                              className="absolute left-1/2 top-1/2 h-full w-full -translate-x-1/2 -translate-y-1/2 object-cover"
                              crop="center"
                              height={Math.floor(400 / (16 / 9))}
                              src={image.src}
                              width="400"
                            />
                          )}
                        </div>

                        <p className="mt-3 text-sm">{caption}</p>
                      </Link>
                    </li>
                  );
                })}
              </ul>
            </>
          )}
        </div>
      )}
    </div>
  );
}

MenuDrawer.displayName = 'MenuDrawer';

import { Image, Link, Svg } from '@snippets';

export function MenuNestedSidebar({
  handleCloseSidebar,
  nestedSidebarContent,
  handleNestedSidebar,
}) {
  const { imageLinks, links, mainLink, menuItem, submenus, useNewSubmenu } = {
    ...nestedSidebarContent,
  };

  return (
    <div
      data-comp={MenuNestedSidebar.displayName}
      className={`scrollbar-hide absolute left-0 top-0 z-[1] h-full w-full bg-background ${
        nestedSidebarContent ? 'visible' : 'invisible'
      }`}
    >
      <div className="scrollbar-hide h-full w-full overflow-y-auto">
        <button
          aria-label="Go back to main menu"
          className="sticky top-0 z-[1] flex h-[3.5rem] w-full items-center justify-between gap-4 border-b border-b-border bg-background p-4"
          onClick={() => handleNestedSidebar(null)}
          type="button"
        >
          <Svg
            className="w-5"
            src="/svgs/arrow-left.svg#arrow-left"
            title="Arrow Left"
            viewBox="0 0 24 24"
          />

          <h3 className="text-nav flex-1 text-left">{menuItem?.text}</h3>
        </button>

        <div className="px-4 pt-5">
          <ul className="mb-8 flex flex-col gap-2">
            {useNewSubmenu ? (
              <div className="flex flex-1 flex-col flex-wrap gap-8">
                {submenus?.map((submenu, index) => {
                  const { submenuHeading, submenuItems } = submenu;

                  if (!submenuHeading || !submenuItems) return null;

                  return (
                    <div key={index} className="w-1/4 min-w-[200px]">
                      {/* Submenu Heading */}
                      <Link
                        aria-label={submenuHeading.text}
                        href={submenuHeading.link.url}
                        newTab={submenuHeading.link.type === 'isExternal'}
                        className={`font-semibold ${
                          submenuHeading.fontSize === 'lg'
                            ? 'text-lg'
                            : 'text-base'
                        }`}
                        style={{ color: submenuHeading.color || 'inherit' }}
                        onClick={handleCloseSidebar}
                      >
                        {submenuHeading.text}
                      </Link>

                      {/* Submenu Items */}
                      <ul className="mt-2 flex flex-col gap-2">
                        {submenuItems.map(({ submenuItem }, itemIndex) => (
                          <li key={itemIndex}>
                            <Link
                              aria-label={submenuItem.text}
                              className="hover:text-underline"
                              href={submenuItem.url}
                              newTab={submenuItem.type === 'isExternal'}
                              onClick={handleCloseSidebar}
                              type={submenuItem.type}
                            >
                              {submenuItem.text}
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </div>
                  );
                })}
              </div>
            ) : (
              <div>
                {links?.map(({ link }, index) => {
                  return (
                    <li key={index}>
                      <Link
                        aria-label={link?.text}
                        className="hover-text-underline"
                        href={link?.url}
                        newTab={link?.newTab}
                        onClick={handleCloseSidebar}
                        type={link?.type}
                      >
                        {link?.text}
                      </Link>
                    </li>
                  );
                })}
              </div>
            )}
          </ul>

          {mainLink?.text && (
            <Link
              aria-label={mainLink.text}
              className="btn-primary mb-8"
              href={mainLink.url}
              newTab={mainLink.newTab}
              onClick={handleCloseSidebar}
              type={mainLink.type}
            >
              {mainLink.text}
            </Link>
          )}

          {imageLinks?.length > 0 && (
            <ul className="mb-8 flex flex-col gap-5">
              {imageLinks.map(({ alt, caption, image, link }, index) => {
                return (
                  <li key={index}>
                    <Link
                      aria-label={caption}
                      href={link?.url}
                      newTab={link?.newTab}
                      onClick={handleCloseSidebar}
                      type={link?.type}
                    >
                      <div className="relative aspect-[16/9] w-full overflow-hidden bg-offWhite">
                        {image?.src && (
                          <Image
                            alt={alt}
                            className="absolute left-1/2 top-1/2 h-full w-full -translate-x-1/2 -translate-y-1/2 object-cover"
                            crop="center"
                            height={Math.floor(768 / (16 / 9))}
                            src={image.src}
                            width="768"
                          />
                        )}
                      </div>

                      <p className="mt-3 text-sm">{caption}</p>
                    </Link>
                  </li>
                );
              })}
            </ul>
          )}
        </div>
      </div>
    </div>
  );
}

MenuNestedSidebar.displayName = 'MenuNestedSidebar';
